.copy-alert{

    opacity: 0;
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 20px;
    color: white;
    border-radius: 5px;
    /*background: rgb(34,221,216);
    background: linear-gradient(45deg, rgba(34,221,216,1) 0%, rgba(162,0,255,1) 100%);*/
    transition: 0.5s;
}

.copy-alert-active{

    opacity: 1;
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 20px;
    color: white;
    border-radius: 5px;
    /*background: rgb(34,221,216);
    background: linear-gradient(45deg, rgba(34,221,216,1) 0%, rgba(162,0,255,1) 100%);*/
    transition: 0.5s;
}