.palette-section{

    position: relative;
    padding: 100px 50px;
}

.palette-title{

    text-align: center;
    font-size: 2rem;
    font-weight: bolder;
    text-transform: capitalize;
}

.palette-container{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.color-container{

    padding: 5px;
}

.color-holder{

    display: flex;
    justify-content: center;
    align-items: center;
    height: 115px;
    width: 115px;
    border-radius: 5px;
    margin: auto;
    cursor: pointer;
}

.copy-icon{

    font-size: 2.5rem;
    opacity: 0;
    transition: 0.5s;
}

.color-holder:hover .copy-icon{

    opacity: 1;
}

.color-text{

    text-align: center;
}

.underline{

    position: absolute;
    height: 1px;
    width: 50%;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(230, 230, 230);
}