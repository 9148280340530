@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

*{

    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.first-section{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: rgb(34,221,216);
    background: linear-gradient(45deg, rgba(34,221,216,1) 0%, rgba(162,0,255,1) 100%);
    
}

.page-title{

    width: 100%;
    text-align: center;
    color: white;
    font-size: 3rem;
    font-weight: bolder;
}

.form-container{

    width: fit-content;
    margin: 0 auto;
}

.color-input-container {

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 14px 1px rgba(00,00,00,0.2);
    box-shadow: 10px 10px 14px 1px rgba(00,00,00,0.2);
}
  
.color-input-container > label{

    position: absolute;
}

.color-input-container input[type="color"] {

    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.color-input-container input[type="color"]::-webkit-color-swatch-wrapper {
border-radius: 50%;
padding: 0;
}

.color-input-container input[type="color"]::-webkit-color-swatch {
border: none;
}

.color-input-container input[type="color"]::-moz-color-swatch {
border: none;
}

.color-input-container input[type="color"]::-moz-focus-inner {
border: none;
}
  
.generate-button{

    display: block;
    border: none;
    outline: none;
    padding: 10px 40px;
    margin-top: 20px;
    border-radius: 50px;
    background-color: rgba(34,221,216,1);
    color: white;
    font-size: 1.2rem;
    -webkit-box-shadow: 10px 10px 14px 1px rgba(00,00,00,0.2);
    box-shadow: 10px 10px 14px 1px rgba(00,00,00,0.2);
    cursor: pointer;

}

.reveal{

    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1.2s;
}

.reveal-active{
    
    transform: translate(0px);
    opacity: 1;
}
  
  
